import { Link, PageProps, graphql, useStaticQuery } from "gatsby";
import React, { FC, ReactNode } from "react";

import Footer from "./footer";
import Header from "./Header";

const Layout: FC<
  PageProps<Queries.PageDataQuery> & { children: ReactNode }
> = ({ children, data }) => {
  const meta = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={meta.site.siteMetadata.title} />

      <main>{children}</main>

      <Footer />
    </>
  );
};

export default Layout;
