import React, { useContext } from "react";

import { FormattedMessage } from "react-intl";
import { Link } from "gatsby";
import { LinkButton } from "./ui/Button";
import { LocaleContext } from "../locales-utils";
import StrokeMail from "../svgs/stroke-mail.svg";
import Typo from "./ui/Typo";
import footerMobileLogo from "../images/Footer.png";

export default function Footer() {
  const locale = useContext(LocaleContext);

  return (
    <footer className="relative lg:pt-16 pt-10 pb-6 px-8 lg:px-16 text-white Footer bg-primary lg:pr-[33%]">
      <div className="max-w-4xl">
        <Typo
          variant="chapo"
          as="div"
          className="mb-5 lg:mb-10"
          weight="font-bold"
        >
          {locale.footer?.text || (
            <FormattedMessage
              id="footer.text"
              defaultMessage="Blunomy works alongside go-getting companies, financial institutions, entrepreneurs and tech experts to drive the transition to a positive economy."
            />
          )}
        </Typo>
        <LinkButton
          as="a"
          to=""
          href="mailto:hello@theblunomy.com"
          variant="cta"
          theme="secondary"
        >
          <FormattedMessage id="footer.text" defaultMessage="Contact us" />
        </LinkButton>

        <div
          style={{ backgroundImage: `url(${footerMobileLogo})` }}
          className="w-64 h-40 ml-auto -mt-8 bg-right-top bg-no-repeat bg-contain lg:hidden"
        ></div>

        <div className="mt-20">
          <Typo
            variant="p"
            as="div"
            className="flex flex-row flex-wrap text-xs leading-none divide-x md:items-center md:text-base "
          >
            <span className="pr-2 mb-2 md:mb-0 md:pr-4 md:py-0">
              <FormattedMessage
                id="footer.copyright"
                defaultMessage="© Blunomy 2022 — All rights reserved"
              />
            </span>

            <span className="px-2 mb-2 md:px-4 md:py-0 md:mb-0">
              <Link to={locale.footer?.legalTerms?.path}>
                {locale.footer?.legalTerms?.blunomyContent?.title}
              </Link>
            </span>
            <span className="px-2 mb-2 md:px-4 md:py-0 md:mb-0">
              <Link to={locale.footer?.privacy?.path}>
                {locale.footer?.privacy?.blunomyContent?.title}
              </Link>
            </span>
            <span className="px-2 mb-2 md:px-4 md:py-0 md:mb-0">
              <a href="https://enea-consulting.com" target={"_blank"}>
                enea-consulting.com
              </a>
            </span>
          </Typo>
        </div>
      </div>

      <div>
        <StrokeMail className="absolute right-0 hidden w-1/2 -translate-y-1/2 pointer-events-none lg:w-auto top-1/2 lg:block" />
      </div>
    </footer>
  );
}
