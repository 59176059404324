import "./Header.css";

import { FormattedMessage, useIntl } from "react-intl";
import { Link, graphql, useStaticQuery } from "gatsby";
import { LocalesKeys, defaultLocale, localesList } from "../../../locales";
import React, { useContext, useRef, useState } from "react";
import { useClickAway, useLockBodyScroll } from "react-use";

import AngleDownIcon from "../../svgs/angle-down-solid.svg";
import ArrowRightIcon from "../../svgs/arrow-right-solid.svg";
import LinkedinIcon from "../../svgs/linkedin.svg";
import { LocaleContext } from "../../locales-utils";
import LogoSvg from "../../svgs/logo.svg";
import Sticky from "react-stickynode";

const getLinkByLocale = (
  links:
    | Queries.HeaderQuery["preHeader"]["nodes"]
    | Queries.HeaderQuery["header"]["nodes"],
  locale: LocalesKeys | string
) => {
  return links.find((link) => link.context?.locale === locale);
};

function getChangeLocaleLinks(
  defaultLink: Queries.PageDataQuery["defaultLink"],
  alternateLinks: Queries.PageDataQuery["alternateLinks"]["nodes"] = []
) {
  let links: Record<
    string,
    {
      title: string;
      path: string;
      disabled: boolean;
    }
  > = {};

  for (const locale of localesList) {
    links[locale] = {
      title: locale,
      path: locale === defaultLocale ? "/" : "/" + locale,
      disabled: false,
    };
  }

  for (const link of alternateLinks) {
    if (link.context?.locale) {
      links[link.context?.locale] = {
        title: link.context?.locale,
        path: link.path,
        disabled: false,
      };
    }
  }

  if (defaultLink?.context?.locale) {
    links[defaultLink?.context?.locale] = {
      title: defaultLink.context?.locale,
      path: defaultLink.path,
      disabled: true,
    };
  }

  return Object.values(links);
}

const Header = ({ siteTitle }: { siteTitle: string }) => {
  const data = useStaticQuery<Queries.HeaderQuery>(graphql`
    query Header {
      site {
        siteMetadata {
          title
        }
      }
      careers: allSitePage(
        filter: {
          context: { source: { eq: "pages" }, name: { eq: "careers" } }
        }
      ) {
        nodes {
          ...pageBasicData
        }
      }
      news: allSitePage(
        filter: {
          context: {
            source: { eq: "pages" }
            name: { eq: "news" }
            pageNumber: { eq: 0 }
          }
        }
      ) {
        nodes {
          ...pageBasicData
        }
      }
      offices: allSitePage(
        filter: {
          context: { source: { eq: "pages" }, name: { eq: "offices" } }
        }
      ) {
        nodes {
          ...pageBasicData
        }
      }
      purpose: allSitePage(
        filter: {
          context: { source: { eq: "pages" }, name: { eq: "our-purpose" } }
        }
      ) {
        nodes {
          ...pageBasicData
        }
      }
      approach: allSitePage(
        filter: {
          context: { source: { eq: "pages" }, name: { eq: "our-approach" } }
        }
      ) {
        nodes {
          ...pageBasicData
        }
      }
      about: allSitePage(
        filter: {
          context: { source: { eq: "pages" }, name: { eq: "about-us" } }
        }
      ) {
        nodes {
          ...pageBasicData
        }
      }
      tools: allSitePage(
        filter: {
          context: { source: { eq: "pages" }, name: { eq: "our-tools" } }
        }
      ) {
        nodes {
          ...pageBasicData
        }
      }
      cases: allSitePage(
        filter: {
          context: { source: { eq: "pages" }, name: { eq: "our-case-studies" } }
        }
      ) {
        nodes {
          ...pageBasicData
        }
      }
      publications: allSitePage(
        filter: {
          context: {
            source: { eq: "pages" }
            name: { eq: "publications" }
            pageNumber: { eq: 0 }
          }
        }
      ) {
        nodes {
          ...pageBasicData
        }
      }
    }
  `);

  const locale = useContext(LocaleContext);
  const intl = useIntl();

  const [openMenu, setOpenMenu] = useState(false);
  const [langDropdown, setLangDropdown] = useState(false);
  const refLangDropdown = useRef(null);

  useClickAway(refLangDropdown, () => {
    setLangDropdown(false);
  });
  useLockBodyScroll(openMenu);

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };
  const handleLangClick = () => {
    setLangDropdown(!langDropdown);
  };

  const linksPreHeader = [data.careers, data.offices] // add data.news when ready on blunomy side
    .map((n) => n.nodes)
    .map((nodes) => {
      const link = getLinkByLocale(nodes, locale.currentLocale) as any;
      return {
        title: link?.blunomyContent?.title || ("" as string),
        path: link?.path,
      };
    });

  const linksHeader = [
    data.purpose,
    data.approach,
    data.about,
    data.tools,
    data.cases,
    data.publications,
  ]
    .map((n) => n.nodes)
    .map((nodes) => {
      const link = getLinkByLocale(nodes, locale.currentLocale) as any;

      return {
        title: link?.blunomyContent?.title || ("" as string),
        path: link?.path,
      };
    });

  const links = getChangeLocaleLinks(
    locale?.defaultLink,
    locale?.alternateLinks
  );

  const renderMain = (
    <nav className="Header-main">
      {linksHeader.slice(0, Math.round(linksHeader.length / 2)).map((link) => {
        return link.path && link.title ? (
          <Link
            key={link.path}
            to={link.path}
            className="Header-mainLink"
            partiallyActive
            activeClassName="is-active"
            onClick={() => {
              setOpenMenu(false);
            }}
          >
            {link.title}
            <ArrowRightIcon className="Header-mainLinkIcon" />
          </Link>
        ) : null;
      })}
      <div className="Header-wrapLogo">
        <Link
          className="Header-logo"
          to="/"
          title={siteTitle}
          onClick={() => {
            setOpenMenu(false);
          }}
        >
          <LogoSvg />
        </Link>
        <button
          className={`Header-menuBtn ${openMenu ? "is-active" : ""}`}
          type="button"
          onClick={handleOpenMenu}
          title={
            openMenu
              ? intl.formatMessage({
                  id: "header.closeMenu",
                  defaultMessage: "Close menu",
                })
              : intl.formatMessage({
                  id: "header.openMenu",
                  defaultMessage: "Open menu",
                })
          }
        ></button>
      </div>
      {linksHeader.slice(Math.round(linksHeader.length / 2)).map((link) => {
        return link.path && link.title ? (
          <Link
            key={link.path}
            to={link.path}
            className="Header-mainLink"
            partiallyActive
            activeClassName="is-active"
            onClick={() => {
              setOpenMenu(false);
            }}
          >
            {link.title}
            <ArrowRightIcon className="Header-mainLinkIcon" />
          </Link>
        ) : null;
      })}
    </nav>
  );

  return (
    <header className={`Header ${openMenu ? "is-open" : ""}`}>
      <nav className="Header-top">
        {linksPreHeader.map((link, key) => {
          return link.path && link.title ? (
            <Link
              key={link.path}
              to={link.path}
              className={`Header-topLink ${
                key === 0 ? "Header-topLink--featured" : ""
              }`}
              partiallyActive
              activeClassName="is-active"
              onClick={() => {
                setOpenMenu(false);
              }}
            >
              {link.title}
            </Link>
          ) : null;
        })}

        <a className="Header-topLink" href="mailto:hello@theblunomy.com">
          <FormattedMessage id="footer.text" defaultMessage="Contact" />
        </a>

        {links.length > 1 ? (
          <div className="Header-lang" ref={refLangDropdown}>
            <button
              className="Header-langBtn"
              type="button"
              onClick={handleLangClick}
            >
              {locale.currentLocale}
              <AngleDownIcon />
            </button>
            {langDropdown && (
              <ul className="Header-langDropdown">
                {links.map((link) => {
                  return (
                    <li key={link.path}>
                      <Link
                        to={link.path}
                        key={link.path}
                        className="Header-langDropdownLink"
                        onClick={() => {
                          setOpenMenu(false);
                        }}
                      >
                        {link.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ) : null}

        <a
          href="https://linkedin.com/company/blunomy"
          target="_blank"
          title="LinkedIn"
          className="Header-linkedin"
        >
          <LinkedinIcon />
        </a>
      </nav>

      <Sticky innerClass="Header-stickyWrapper" innerActiveClass="is-active">
        {renderMain}
      </Sticky>
    </header>
  );
};

export default Header;
