import React from "react";
import type { ReactNode, ButtonHTMLAttributes } from "react";
import { Link } from "gatsby";

import ArrowRightIcon from "../../svgs/arrow-right-solid.svg";

type Variants = {
  [key: string]: {
    class: string;
    themes: {
      [key: string]: string;
    };
  };
};

type ButtonProps<T> = {
  children: ReactNode;
  className?: string;
  variant: "cta" | "secondaryCTA" | "filter" | "icon" | "back";
  theme: "primary" | "secondary" | "tertiary" | "quaternary";
} & ButtonHTMLAttributes<T>;

const variants: Variants = {
  cta: {
    class: "py-3 lg:py-5 px-[30px] text-base",
    themes: {
      primary:
        "bg-transparent text-primary hover:bg-primary hover:text-white border border-primary rounded-full",
      secondary:
        "bg-transparent text-white hover:bg-white hover:text-primary border border-white rounded-full",
    },
  },
  back: {
    class: "py-3 lg:py-5 px-[30px] text-base flex-row-reverse",
    themes: {
      primary:
        "bg-transparent text-primary hover:bg-primary hover:text-white border border-primary rounded-full",
      secondary:
        "bg-transparent text-white hover:bg-white hover:text-primary border border-white rounded-full",
    },
  },
  secondaryCTA: {
    class: "text-base",
    themes: {
      primary:
        "text-primary border-b-2 border-transparent hover:border-primary",
      secondary: "text-white border-b-2 border-transparent hover:border-white",
    },
  },
  filter: {
    class: "py-[5px] px-[15px] text-sm",
    themes: {
      primary:
        "bg-transparent text-primary hover:bg-primary hover:text-white border border-primary rounded-full",
      secondary:
        "bg-transparent text-white hover:bg-white hover:text-primary border border-white rounded-full",
    },
  },
  icon: {
    class: "p-5",
    themes: {
      primary:
        "bg-transparent text-primary hover:bg-primary hover:text-white border border-primary rounded-full",
      secondary:
        "bg-transparent text-white hover:bg-white hover:text-primary border border-white rounded-full",
      tertiary:
        "bg-primary text-white hover:bg-white hover:text-primary border border-primary rounded-full",
      quaternary:
        "bg-white text-primary hover:bg-primary hover:text-white border border-white rounded-full",
    },
  },
};

const getClassName = (variant: string, theme: string) => {
  return (
    "w-max font-bold inline-flex items-center gap-2" +
    " " +
    variants[variant].class +
    " " +
    variants[variant].themes[theme]
  );
};

export const Button = ({
  variant = "cta",
  theme = "primary",
  children,
  className,
  ...rest
}: ButtonProps<HTMLButtonElement>) => {
  return (
    <button
      className={`${getClassName(variant, theme)} ${className}`}
      {...rest}
    >
      {children}
      {variant === "cta" && (
        <ArrowRightIcon className="relative w-4 top-[1px]" />
      )}
    </button>
  );
};

export const LinkButton = ({
  to,
  as = Link,
  variant = "secondaryCTA",
  theme = "primary",
  children,
  className,
  ...rest
}: {
  to?: string;
  as?: string | typeof Link;
} & ButtonProps<HTMLAnchorElement>) => {
  const Element = as;
  return (
    <Element
      to={to}
      className={`${getClassName(variant, theme)} ${className}`}
      {...rest}
    >
      {children}
      {["cta", "secondaryCTA"].includes(variant) && (
        <ArrowRightIcon className="relative w-4 top-[1px]" />
      )}
      {variant === "back" && (
        <ArrowRightIcon className="relative w-4 top-[1px] rotate-180" />
      )}
    </Element>
  );
};
