function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

export enum LocalesKeys {
  en = "en",
  //fr = "fr",
}

export const defaultLocale = LocalesKeys.en;

export const localesList = enumKeys(LocalesKeys);
