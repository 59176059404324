import * as locales from "../locales";

import React, { FC, ReactNode } from "react";

import { IntlProvider } from "react-intl";
import Layout from "./components/layout";
//import browserLang from "browser-lang";
import { createContext } from "react";

type LocaleContextType = typeof locales & {
  currentLocale: string;
  //userLocale: string;
  alternateLinks: Queries.PageDataQuery["alternateLinks"]["nodes"] | null;
  defaultLink: Queries.PageDataQuery["defaultLink"] | null;
  footer: Queries.PageDataQuery["footer"] | null;
};

export const LocaleContext = createContext<LocaleContextType>({
  ...locales,
  currentLocale: locales.defaultLocale,
  // userLocale: locales.defaultLocale,
  alternateLinks: null,
  defaultLink: null,
  footer: null,
});

const LocaleProvider: FC<{
  children: ReactNode;
  pathname: string;
  alternateLinks: Queries.PageDataQuery["alternateLinks"]["nodes"];
  queryLocale?: locales.LocalesKeys;
  defaultLink: Queries.PageDataQuery["defaultLink"];
  footer: {
    text: Queries.PageDataQuery["footerText"];
    legalTerms: Queries.PageDataQuery["legalTerms"];
    privacy: Queries.PageDataQuery["privacy"];
  };
}> = ({
  children,
  alternateLinks,
  pathname,
  queryLocale,
  defaultLink,
  footer,
}) => {
  const pathnameLocale = locales.localesList.find((l) =>
    pathname?.startsWith(`/${l}/`)
  );

  const currentLocale = queryLocale || pathnameLocale || locales.defaultLocale;

  /*
  const userLocale = browserLang({
    languages: locales.localesList,
    fallback: locales.defaultLocale,
  });
  */

  //const messages = require(`../lang/${currentLocale}.compiled.json`);
  return (
    <LocaleContext.Provider
      value={{
        ...locales,
        currentLocale: currentLocale,
        //userLocale
        alternateLinks,
        defaultLink,
        footer,
      }}
    >
      <IntlProvider
        //messages={messages}
        onError={() => {
          // noop for now to hide missing translation error, must be removed before deployment
        }}
        locale={currentLocale}
        defaultLocale={locales.defaultLocale}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export const wrapPageElement = ({
  element,
  props,
}: {
  element: any;
  props: any;
}) => {
  return (
    <LocaleProvider
      pathname={props.path}
      alternateLinks={props.data?.alternateLinks?.nodes}
      defaultLink={props.data?.defaultLink}
      queryLocale={props.data?.blunomyContent?.locale}
      footer={{
        text: props.data?.footerText?.data?.text,
        legalTerms: props.data?.legalTerms,
        privacy: props.data?.privacy,
      }}
    >
      <Layout {...props}>{element}</Layout>
    </LocaleProvider>
  );
};

export function getAlternateLinksFromPageData(
  data: Queries.PageDataQuery["alternateLinks"]
) {
  return data;
}
