import React, { ReactNode, Suspense } from "react";

export type TypoProps = {
  children?: ReactNode;
  content?: string;
  variant?:
    | "h0"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "chapo"
    | "p"
    | "tag"
    | "link"
    | "small";
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "span" | "a" | "div";
  weight?: string;
  decoration?: string;
  font?: string;
  color?: string;
  size?: string;
  className?: string;
} & React.HTMLAttributes<
  | HTMLDivElement
  | HTMLHeadingElement
  | HTMLParagraphElement
  | HTMLSpanElement
  | HTMLAnchorElement
>;

const variantMap = {
  h0: {
    size: "text-6xl lg:text-8xl",
    font: "font-krona",
    color: "text-primary",
    weight: "",
    decoration: "",
  },
  h1: {
    size: "text-5xl lg:text-6xl",
    font: "font-krona",
    color: "text-primary",
    weight: "",
    decoration: "",
  },
  h2: {
    size: "text-2xl lg:text-5xl",
    font: "font-krona",
    color: "text-primary",
    weight: "",
    decoration: "",
  },
  h3: {
    size: "text-3xl lg:text-4xl",
    font: "font-openSans",
    color: "text-primary",
    weight: "font-extrabold",
    decoration: "",
  },
  h4: {
    size: "text-2xl",
    font: "font-openSans",
    color: "",
    weight: "font-bold",
    decoration: "",
  },
  chapo: {
    size: "text-2xl lg:text-chapo",
    font: "font-openSans",
    color: "",
    weight: "font-semibold",
    decoration: "",
  },
  p: {
    size: "text-base",
    font: "font-openSans",
    color: "",
    decoration: "",
  },
  tag: {
    size: "text-xs",
    font: "font-krona",
    color: "text-primary",
    weight: "",
    decoration: "uppercase",
  },
  link: {
    size: "text-base",
    font: "font-openSans",
    color: "",
    weight: "font-bold",
    decoration: "",
  },
  small: {
    size: "text-small",
    font: "font-openSans",
    color: "",
    decoration: "",
  },
};

const Typo = ({
  children,
  content,
  as,
  variant = "p",
  className,
  color,
  size,
  decoration,
  font,
  weight,
  ...rest
}: TypoProps) => {
  const Tag = as || (variant as keyof typeof as) || "div";

  const {
    color: variantColor,
    size: variantSize,
    decoration: variantDecoration,
    font: variantFont,
    weight: variantWeight,
  } = variantMap[variant];

  const colorClass = color || variantColor || "";
  const sizeClass = size || variantSize || "";
  const decorationClass = decoration || variantDecoration || "";
  const fontClass = font || variantFont || "";
  const weightClass = weight || variantWeight || "";

  if (content) {
    return (
      <Tag
        className={`Typo ${colorClass} ${sizeClass} ${decorationClass} ${fontClass} ${weightClass} ${
          className || ""
        }`}
        {...rest}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }

  return (
    <Tag
      className={`Typo ${colorClass} ${sizeClass} ${decorationClass} ${fontClass} ${weightClass} ${
        className || ""
      }`}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default Typo;
