exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-us-tsx": () => import("./../../../src/templates/about-us.tsx" /* webpackChunkName: "component---src-templates-about-us-tsx" */),
  "component---src-templates-careers-tsx": () => import("./../../../src/templates/careers.tsx" /* webpackChunkName: "component---src-templates-careers-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-legal-terms-tsx": () => import("./../../../src/templates/legal-terms.tsx" /* webpackChunkName: "component---src-templates-legal-terms-tsx" */),
  "component---src-templates-news-details-tsx": () => import("./../../../src/templates/newsDetails.tsx" /* webpackChunkName: "component---src-templates-news-details-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-offices-tsx": () => import("./../../../src/templates/offices.tsx" /* webpackChunkName: "component---src-templates-offices-tsx" */),
  "component---src-templates-our-approach-tsx": () => import("./../../../src/templates/our-approach.tsx" /* webpackChunkName: "component---src-templates-our-approach-tsx" */),
  "component---src-templates-our-case-studies-tsx": () => import("./../../../src/templates/our-case-studies.tsx" /* webpackChunkName: "component---src-templates-our-case-studies-tsx" */),
  "component---src-templates-our-purpose-tsx": () => import("./../../../src/templates/our-purpose.tsx" /* webpackChunkName: "component---src-templates-our-purpose-tsx" */),
  "component---src-templates-our-tools-tsx": () => import("./../../../src/templates/our-tools.tsx" /* webpackChunkName: "component---src-templates-our-tools-tsx" */),
  "component---src-templates-privacy-tsx": () => import("./../../../src/templates/privacy.tsx" /* webpackChunkName: "component---src-templates-privacy-tsx" */),
  "component---src-templates-publication-tsx": () => import("./../../../src/templates/publication.tsx" /* webpackChunkName: "component---src-templates-publication-tsx" */),
  "component---src-templates-publications-tsx": () => import("./../../../src/templates/publications.tsx" /* webpackChunkName: "component---src-templates-publications-tsx" */),
  "component---src-templates-tool-tsx": () => import("./../../../src/templates/tool.tsx" /* webpackChunkName: "component---src-templates-tool-tsx" */)
}

